import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  AwaitingSubject,
  SubscriberSentinel,
  Subscription,
  Fault,
  User,
  Deletable,
  Identifiable,
  Factory,
  TenantUnit,
  Apartment,
  Building,
  Entrance,
  Invite,
  Level,
  Room,
  Post,
  Story,
  PostMessage,
  Event,
  Group,
  SuccessAndFails,
  R20nCategory,
  R20nItem,
  Chat,
  Bookable,
  Booking,
  BookableType,
  GenericBookableCategory,
  Scope,
  TenantUnitRole,
  ApartmentRole,
  AttachmentType,
  HasTenantUnitId,
  HasBuildingId,
  HasCategoryId,
  ApartmentEmailInvite,
  DigitalSignage,
  DigitalSignageConnection,
  EstablishedConnection,
  SeveredConnection,
  AwaitingConnection,
} from '../../models';
import { Subscription as rxSub } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import { NewCacheService } from '../database.service';
import { SrenityAccessGroup, SrenityPerson } from 'src/app/shared/modals/user-modal/user-modal.component';

const cache = {};
@Injectable({
  providedIn: 'root',
})
export class CacheService {
  //#region Fields

  private readonly apiUrl: string = environment.apiUrl;
  private readonly acceptHeader: string = environment.acceptHeader;

  private db?: IDBDatabase;
  private dbUpgradeNeeded: boolean;
  private userPoll: Worker;
  private tenantUnitPoll: Worker;
  private cache?: UserCache;

  // private users$: AwaitingSubject<User>;
  private tenantUnits$: AwaitingSubject<TenantUnit>;
  private apartments$: AwaitingSubject<Apartment>;
  private buildings$: AwaitingSubject<Building>;
  private entrances$: AwaitingSubject<Entrance>;
  private levels$: AwaitingSubject<Level>;
  private rooms$: AwaitingSubject<Room>;
  private posts$: AwaitingSubject<Post>;
  private postMessages$: AwaitingSubject<PostMessage>;
  private events$: AwaitingSubject<Event>;
  private groups$: AwaitingSubject<Group>;
  private stories$: AwaitingSubject<Story>;
  private r20nCategories$: AwaitingSubject<R20nCategory>;
  private r20nItems$: AwaitingSubject<R20nItem>;
  private chats$: AwaitingSubject<Chat>;
  private bookables$: AwaitingSubject<Bookable>;
  private bookings$: AwaitingSubject<Booking>;
  private genericBookableCategories$: AwaitingSubject<GenericBookableCategory>;
  private invites$: AwaitingSubject<Invite>;
  private digitalSignages$: AwaitingSubject<DigitalSignage>;
  private digitalSignageConnections$: AwaitingSubject<DigitalSignageConnection>;

  //#endregion

  //#region Initialization
  constructor(private http: HttpClient, private newCacheService: NewCacheService) {
    // this.users$ = new AwaitingSubject();
    this.tenantUnits$ = new AwaitingSubject();
    this.apartments$ = new AwaitingSubject();
    this.buildings$ = new AwaitingSubject();
    this.entrances$ = new AwaitingSubject();
    this.levels$ = new AwaitingSubject();
    this.rooms$ = new AwaitingSubject();
    this.posts$ = new AwaitingSubject();
    this.postMessages$ = new AwaitingSubject();
    this.events$ = new AwaitingSubject();
    this.groups$ = new AwaitingSubject();
    // this.stories$ = new AwaitingSubject();
    this.r20nCategories$ = new AwaitingSubject();
    this.r20nItems$ = new AwaitingSubject();
    this.chats$ = new AwaitingSubject();
    this.bookables$ = new AwaitingSubject();
    this.bookings$ = new AwaitingSubject();
    this.genericBookableCategories$ = new AwaitingSubject();
    this.invites$ = new AwaitingSubject();
    this.digitalSignages$ = new AwaitingSubject();
    this.digitalSignageConnections$ = new AwaitingSubject();

    const self = this;

    // Initiate cache and start polling if local storage has data from previous login.
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const userId = localStorage.getItem('userId');

    if (accessToken !== null && refreshToken !== null && userId !== null) {
      const c = new UserCache(accessToken, refreshToken, userId);
      this.cache = c;
    }
  }
  //#endregion

  //#region Communications

  async send(
    method: string,
    endpoint: string,
    body?: object,
    cache?: UserCache,
    inputParams?: Object,
    refresh: boolean = true
  ): Promise<object> {
    if (method === 'POST' || method === 'PUT') {
      if (!body) {
        throw new Error('Body can not be undefined for POST or PUT requests.');
      }
    } else if (body) {
      throw new Error('Only POST and PUT requests can have a body.');
    }
    const options = {
      headers: {
        Accept: this.acceptHeader,
        Range: 'items=0-',
      },
    };
    const accessToken = localStorage.getItem('accessToken');

    if (cache) {
      options['headers']['Authorization'] = 'Bearer ' + accessToken;
    }

    if (method === 'PUT') {
      options['Content-Type'] = 'multipart/form-data;';
    }

    try {
      if (method === 'GET') {
        let params = new HttpParams();
        if (inputParams) {
          for (const [paramKey, paramValue] of Object.entries(inputParams)) {
            params = params.set(paramKey, paramValue);
          }
        }
        const res = await firstValueFrom(this.http.get(this.apiUrl + endpoint, { ...options, params }));
        return res;
      }
      switch (method) {
        case 'POST': {
          const res = await firstValueFrom(this.http.post(this.apiUrl + endpoint, body, options));
          return res;
        }
        case 'PUT': {
          const res = await firstValueFrom(this.http.put(this.apiUrl + endpoint, body, options));
          return res;
        }
        case 'DELETE': {
          const res = await firstValueFrom(this.http.delete(this.apiUrl + endpoint, options));
          return res;
        }
        default:
          throw new Error('Unrecognized HTTP method: ' + method);
      }
    } catch (err) {
      if (err && err.status == 401 && cache !== null && refresh === true) {
        try {
          if (!cache) {
            throw new Error('Cache was uninitialized when refreshing token.');
          }
          await this.refreshToken();
          return this.send(method, endpoint, body, cache);
        } catch (x) {
          const f = err.error?.fault;
          if (f) {
            // Is this a fault?
            throw new Fault(f);
          } else {
            throw err;
          }
        }
      } else {
        const f = err.error?.fault;
        if (f) {
          // Is this a fault?
          throw new Fault(f);
        } else {
          throw err;
        }
      }
    }
  }

  public async announceToTenantUnit(tenantUnitId: string, message: string): Promise<SuccessAndFails> {
    if (!tenantUnitId) {
      throw new Error('Attempting to send a Push Notification without specifying a tenant unit.');
    }

    if (!message) {
      throw new Error('Attempting to send a Push Notification without a message.');
    }

    const cache = this.cache;
    if (!cache) {
      throw new Error('Attempting to send a Push Notification without being signed in.');
    }

    const endpoint = '/tenant_units/' + tenantUnitId + '/push_notification';
    const res = (await this.send('POST', endpoint, { data: message }, cache)) as DataResponse;

    return res.data;
  }

  //#endregion

  //#region Active

  getActiveUserId(): string | undefined {
    const userId = localStorage.getItem('userId');
    return userId;
  }

  async getActiveUser(): Promise<User | undefined> {
    const cacheKey = 'activeUser';
    const cachedUser = this.cache[cacheKey];
    //const cachedUser: User = this.newCacheService.getUser(cacheKey);

    if (cachedUser) {
      // If user is in cache, return it immediately
      //console.log('Returning user from cache:', cachedUser);
      return new User(cachedUser);
    }

    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.error('No userId found in localStorage');
      return undefined;
    }
    try {
      const user = await this.getUser(userId);
      if (user) {
        // Update both in-memory cache and external cache
        this.cache[cacheKey] = user;
        this.newCacheService.setUserOrTu(cacheKey, user);
      }

      //console.log('Fetched user from database:', user);
      return new User(user);
    } catch (error) {
      console.error('Error fetching user:', error);
      return undefined;
    }
  }

  // async getActiveUser(): Promise<User | undefined> {
  //   const userId = localStorage.getItem('userId');
  //   const user = await this.getUser(userId);
  //   console.log(user);
  //   return user;
  // }

  async setActiveTenantUnit(tenantUnitId: any, tenantUnit: TenantUnit) {
    const cacheKey = 'activeTenantUnit';
    localStorage.setItem('tenantUnitId', tenantUnitId);
    if (tenantUnit) {
      this.cache[cacheKey] = tenantUnit;
      this.newCacheService.setUserOrTu(cacheKey, tenantUnit);
    }
  }

  setActiveTenantUnitId(tenantUnitId: string) {
    localStorage.setItem('tenantUnitId', tenantUnitId);
  }

  getActiveTenantUnitId(): string | undefined {
    const tenantUnitId = localStorage.getItem('tenantUnitId');
    return tenantUnitId;
  }

  async getActiveTenantUnit(): Promise<TenantUnit | undefined> {
    const cacheKey = 'activeTenantUnit';
    const cachedTenantUnit = this.cache[cacheKey];

    if (cachedTenantUnit) {
      return cachedTenantUnit;
    }

    const activeTenantUnitId = localStorage.getItem('tenantUnitId');
    if (!activeTenantUnitId) {
      console.error('No activeTenantUnitId found in localStorage');
      return undefined;
    }
    try {
      const tu: TenantUnit = await this.getEntityFromApi(
        TenantUnit.getUrl(activeTenantUnitId),
        TenantUnit.getFactory()
      );
      if (tu) {
        this.cache[cacheKey] = tu;
        this.newCacheService.setUserOrTu(cacheKey, tu);
      }
      return tu;
    } catch (error) {
      console.error('Error fetching tu:', error);
      return undefined;
    }
  }

  async updateActiveTanantUnit(newUnit: TenantUnit) {
    const activeUnit = await this.getActiveTenantUnit();
    const cacheKey = 'activeTenantUnit';
    if (newUnit.id === activeUnit.id) {
      try {
        const tu: TenantUnit = await this.editTenantUnit(newUnit);
        if (tu) {
          this.cache[cacheKey] = null;
          this.newCacheService.setUserOrTu(cacheKey, tu);
        }
      } catch (error) {
        console.error('Error updating tenantunit:', error);
      }
    }
  }

  //#endregion

  //#region Sign Up/In/Out

  async refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    const body = { data: refreshToken };
    const res = (await this.send(
      'POST',
      '/users/' + this.cache.userId + '/token/refresh',
      body,
      this.cache
    )) as DataResponse;

    if (!res.data) {
      throw new Error('Token refresh response has no data.');
    }

    this.cache.accessToken = res.data;
    localStorage.setItem('accessToken', this.cache.accessToken); // Persist.
  }

  async signInByEmail(email: string, password: string) {
    if (!email) {
      throw new Error('Trying to log in without email.');
    }

    if (!password) {
      throw new Error('Trying to log in without password.');
    }

    const res = (await this.send('POST', '/users/bo/signin/email', { data: email, extra: password })) as SigninResponse;

    if (!res.data) {
      throw new Error('Response contained no data when logging in by email.');
    }

    const cache = new UserCache(res.data.accessToken, res.data.refreshToken, res.data.userId);

    // Persist
    localStorage.setItem('accessToken', res.data.accessToken);
    localStorage.setItem('refreshToken', res.data.refreshToken);
    localStorage.setItem('userId', res.data.userId);

    this.cache = cache;
  }

  async changePassword(oldPassword: string, newPassword: string) {
    if (!oldPassword) {
      throw new Error('Trying to change password without old password');
    }

    if (!newPassword) {
      throw new Error('Trying to change password without new password');
    }

    const cache = this.cache;
    if (!cache) {
      throw new Error('Attempting to change password without being signed in');
    }

    await this.send(
      'PUT',
      `/users/${cache.userId}/bo/password`,
      {
        data: newPassword,
        extra: oldPassword,
      },
      cache
    );
  }

  async forgotPassword(email: string) {
    if (!email) {
      throw new Error('Trying to change password without new password');
    }

    await this.send('POST', `/users/bo/password/forgot`, {
      data: email,
    });
  }

  async signOut() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('tenantUnitId');
    this.cache = undefined;
  }

  async resetCache() {
    this.userPoll.postMessage({ type: UserPollRequestType.Signout });
    this.tenantUnitPoll.postMessage({ type: TenantUnitPollRequestType.Signout });

    localStorage.clear();

    await new Promise<any>((resolve, reject) => {
      const req: IDBOpenDBRequest = indexedDB.deleteDatabase('VozDB');

      req.onblocked = (event) => {
        console.log("Couldn't delete database due to the operation being blocked.", event);
        this.db.close();
      };

      req.onerror = (event) => {
        console.error('Error deleting database.', event);
        reject(event);
      };

      req.onsuccess = (event) => {
        console.log('Database deleted successfully.', event);
        resolve(event); // event.result should be undefined on success
      };
    });

    this.cache = undefined;
  }

  //#endregion

  //#region Subscription

  private async subscribe<T extends Deletable>(
    factory: Factory<T>,
    observable: AwaitingSubject<T>,
    next: (value: T | SubscriberSentinel) => Promise<void>,
    deleted: boolean = true
  ): Promise<Subscription<T>> {
    // Get active user.
    const c = this.cache;
    if (!c) {
      throw new Error('Cannot subscribe without being signed in.');
    }

    const s = observable.subscribe({
      next: async (c) => {
        if (c instanceof SubscriberSentinel || deleted || !c.deleted) {
          await next(c);
        }
      },
    });

    // Provide all in db.
    // const entities = await this.getAllEntitiesFromDb(factory);
    // for (let i = 0; i < entities.length; i++) {
    //   if (deleted || !entities[i].deleted) {
    //     await next(entities[i]);
    //   }
    // }

    await next(new SubscriberSentinel()); // Send signal that all available have been sent.

    return s;
  }

  private async subscribeByTenantUnitId<T extends HasTenantUnitId>(
    tenantUnitId: string,
    factory: Factory<T>,
    observable: AwaitingSubject<T>,
    next: (value: T | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<T>> {
    const c = this.cache;
    if (!c) {
      throw new Error('Cannot subscribe without being signed in.');
    }

    const sub = observable.subscribe({
      next: async (o) => {
        if (o instanceof SubscriberSentinel || o.tenantUnitId === tenantUnitId) {
          await next(o);
        }
      },
    });

    // Provide all in DB.

    // Send signal that all available have been sent.
    await next(new SubscriberSentinel());

    return sub;
  }

  private async subscribeByBuildingId<T extends HasBuildingId>(
    buildingId: string,
    factory: Factory<T>,
    observable: AwaitingSubject<T>,
    next: (value: T | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<T>> {
    const c = this.cache;
    if (!c) {
      throw new Error('Cannot subscribe without being signed in.');
    }

    const sub = observable.subscribe({
      next: async (o) => {
        if (o instanceof SubscriberSentinel || o.buildingId === buildingId) {
          await next(o);
        }
      },
    });

    // Provide all in DB.

    // Send signal that all available have been sent.
    await next(new SubscriberSentinel());

    return sub;
  }

  private async subscribeByCategoryId<T extends HasCategoryId>(
    categoryId: string,
    factory: Factory<T>,
    observable: AwaitingSubject<T>,
    next: (value: T | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<T>> {
    const c = this.cache;
    if (!c) {
      throw new Error('Cannot subscribe without being signed in.');
    }

    const sub = observable.subscribe({
      next: async (o) => {
        if (o instanceof SubscriberSentinel || o.categoryId === categoryId) {
          await next(o);
        }
      },
    });

    // Provide all in DB.

    // Send signal that all available have been sent.
    await next(new SubscriberSentinel());

    return sub;
  }

  private async subscribeToObject<T extends Identifiable>(
    id: string,
    factory: Factory<T>,
    classIdentifier: any,
    observable: AwaitingSubject<T>,
    next: (value: T | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<T>> {
    // Get active user.
    const c = this.cache;
    if (!c) {
      throw new Error('Cannot subscribe without being signed in.');
    }

    const s = observable.subscribe({
      next: async (c) => {
        if (c instanceof SubscriberSentinel || c.id == id) {
          await next(c);
        }
      },
    });

    const o = await this.getEntity(id, factory, classIdentifier.getUrl());
    await next(o);
    await next(new SubscriberSentinel()); // Send signal that case has been sent.

    return s;
  }

  // public async subscribeToUsers(
  //   next: (value: User | SubscriberSentinel) => Promise<void>
  // ): Promise<Subscription<User>> {
  //   return this.subscribe(User.getFactory(), this.users$, next);
  // }

  public async subscribeToApartments(
    tenantUnitId: string,
    next: (value: Apartment | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<Apartment>> {
    return this.subscribeByTenantUnitId(tenantUnitId, Apartment.getFactory(), this.apartments$, next);
  }

  public async subscribeToBuildings(
    tenantUnitId: string,
    next: (value: Building | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<Building>> {
    return this.subscribeByTenantUnitId(tenantUnitId, Building.getFactory(), this.buildings$, next);
  }

  public async subscribeToEntrances(
    tenantUnitId: string,
    next: (value: Entrance | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<Entrance>> {
    return this.subscribeByTenantUnitId(tenantUnitId, Entrance.getFactory(), this.entrances$, next);
  }

  public async subscribeToLevels(
    tenantUnitId: string,
    next: (value: Level | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<Level>> {
    return this.subscribeByTenantUnitId(tenantUnitId, Level.getFactory(), this.levels$, next);
  }

  public async subscribeToLevelsByBuilding(
    buildingId: string,
    next: (value: Level | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<Level>> {
    return this.subscribeByBuildingId(buildingId, Level.getFactory(), this.levels$, next);
  }

  public async subscribeToEntrancesByBuilding(
    buildingId: string,
    next: (value: Entrance | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<Entrance>> {
    return this.subscribeByBuildingId(buildingId, Entrance.getFactory(), this.entrances$, next);
  }

  public async subscribeToPostsByAttachmentType(
    attachmentType: AttachmentType,
    tenantUnitId: string,
    next: (value: Post | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<Post>> {
    const c = this.cache;
    if (!c) {
      throw new Error('Cannot subscribe without being signed in.');
    }

    const sub = this.posts$.subscribe({
      next: async (p) => {
        if (
          p instanceof SubscriberSentinel ||
          (p instanceof Post && p.tenantUnitId === tenantUnitId && p.attachment.type === attachmentType)
        ) {
          await next(p);
        }
      },
    });

    // Provide all available in DB.

    // Send signal that all available have been sent.
    await next(new SubscriberSentinel());

    return sub;
  }

  public async subscribeToPostMessages(
    tenantUnitId: string,
    next: (value: PostMessage | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<PostMessage>> {
    return this.subscribeByTenantUnitId(tenantUnitId, PostMessage.getFactory(), this.postMessages$, next);
  }

  public async subscribeToEvents(
    next: (value: Event | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<Event>> {
    return this.subscribe(Event.getFactory(), this.events$, next);
  }

  public async subscribeToGroups(
    next: (value: Group | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<Group>> {
    return this.subscribe(Group.getFactory(), this.groups$, next);
  }

  public async subscribeToStories(
    next: (value: Story | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<Story>> {
    return this.subscribe(Story.getFactory(), this.stories$, next);
  }

  public async subscribeToResidentialInformationCategories(
    next: (value: R20nCategory | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<R20nCategory>> {
    return this.subscribe(R20nCategory.getFactory(), this.r20nCategories$, next);
  }

  public async subscribeToResidentialInformationItems(
    next: (value: R20nItem | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<R20nItem>> {
    return this.subscribe(R20nItem.getFactory(), this.r20nItems$, next);
  }

  public async subscribeToBookables(
    next: (value: Bookable | SubscriberSentinel) => Promise<void>,
    type?: BookableType
  ): Promise<Subscription<Bookable>> {
    const c = this.cache;
    if (!c) {
      throw new Error('Cannot subscribe without being signed in.');
    }

    const s = this.bookables$.subscribe({
      next: async (b) => {
        if (type) {
          if (b instanceof Bookable && b.type === type) {
            await next(b);
          }
        } else {
          await next(b);
        }
      },
    });

    const factory = Bookable.getFactory();

    let entities: Bookable[];
    if (type) {
      // entities = await this.getBookablesFromDBByType(type);
    } else {
      // entities = await this.getAllEntitiesFromDb(factory);
    }

    // Provide all in db.
    for (let i = 0; i < entities.length; i++) {
      await next(entities[i]);
    }

    await next(new SubscriberSentinel());

    return s;
  }

  public async subscribeToBookings(
    next: (value: Booking | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<Booking>> {
    return this.subscribe(Booking.getFactory(), this.bookings$, next);
  }

  public async subscribeToGenericBookableCategories(
    next: (value: GenericBookableCategory | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<GenericBookableCategory>> {
    return this.subscribe(GenericBookableCategory.getFactory(), this.genericBookableCategories$, next);
  }

  public async subscribeToR20nCategories(
    tenantUnitId: string,
    next: (value: R20nCategory | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<R20nCategory>> {
    return this.subscribeByTenantUnitId(tenantUnitId, R20nCategory.getFactory(), this.r20nCategories$, next);
  }

  public async subscribeToR20nItems(
    categoryId: string,
    next: (value: R20nItem | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<R20nItem>> {
    return this.subscribeByCategoryId(categoryId, R20nItem.getFactory(), this.r20nItems$, next);
  }

  public async subscribeToInvites(
    tenantUnitId: string,
    next: (value: Invite | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<Invite>> {
    return this.subscribeByTenantUnitId(tenantUnitId, Invite.getFactory(), this.invites$, next);
  }

  public async subscribeToDigitalSignages(
    tenantUnitId: string,
    next: (value: DigitalSignage | SubscriberSentinel) => Promise<void>
  ): Promise<Subscription<DigitalSignage>> {
    return this.subscribeByTenantUnitId(tenantUnitId, DigitalSignage.getFactory(), this.digitalSignages$, next);
  }

  // public async subscribeToConnectionsByTenantUnitId(
  //   tenantUnitId: string,
  //   next: (value: DigitalSignageConnection | SubscriberSentinel) => Promise<void>
  // ): Promise<Subscription<DigitalSignageConnection>> {
  //   const c = this.cache;
  //   if (!c) {
  //     throw new Error('Cannot subscribe without being signed in.');
  //   }

  //   const sub = this.digitalSignageConnections$.subscribe({
  //     next: async (c) => {
  //       if (
  //         c instanceof SubscriberSentinel ||
  //         (c instanceof EstablishedConnection && c.tenantUnitId === tenantUnitId) ||
  //         (c instanceof SeveredConnection && c.tenantUnitId === tenantUnitId) ||
  //         (c instanceof AwaitingConnection &&
  //           c.credentials !== undefined &&
  //           c.credentials.tenantUnitId === tenantUnitId)
  //       ) {
  //         await next(c);
  //       }
  //     },
  //   });

  //   // Provide all available in DB.
  //   const connections = await this.getAllEntitiesFromDb(DigitalSignageConnection.getFactory());
  //   for (let i = 0; i < connections.length; i++) {
  //     await next(connections[i]);
  //   }

  //   // Send signal that all available have been sent.
  //   await next(new SubscriberSentinel());

  //   return sub;
  // }

  //#endregion

  //#region Get

  public async getEntityFromApi<T>(url: string, factory: Factory<T>): Promise<T> {
    const res = (await this.send('GET', url, undefined, this.cache)) as any;
    if (!res.data) {
      throw new Error('Data not found.');
    }

    return factory.make(res.data);
  }

  // page?: number, pageSize?: number
  public async getEntitiesFromApi<T>(url: string, params?: Object): Promise<T[]> {
    const res = (await this.send('GET', url, undefined, this.cache, params)) as any[];
    if (!res['data']) {
      throw new Error('Data not found.');
    }
    return res;
  }

  // public async getEntityIntervalFromApi<T>(url: string, from?: string, to?: string): Promise<T[]> {
  //   const res = (await this.send(
  //     'GET',
  //     url,
  //     {
  //       from,
  //       to,
  //     },
  //     this.cache,
  //     undefined
  //   )) as any[];
  //   if (!res['data']) {
  //     throw new Error('Data not found.');
  //   }
  //   return res;
  // }

  public async getEntity<T>(id: string, factory: Factory<T>, url: string, invalidate: boolean = false): Promise<T> {
    //

    const table: string = factory.getTableName();

    // Try to look in db.
    const self = this;
    return new Promise<T>(function (resolve, reject) {
      // if (self.db == null) {
      //   throw new Error('DB was not initialized.');
      // }
      // Check db.
      // const os = self.db.transaction([table], 'readonly').objectStore(table);
      // const req = os.get(id);
      // req.onerror = async function (event) {
      //   // Entity not found in db, fetch from server.
      //   try {
      //     const e: T = await self.getEntityFromApi(url, factory);
      //     self.putEntity(e, factory); // Update in db.
      //     resolve(e);
      //   } catch (x) {
      //     reject(x);
      //   }
      // };
      // req.onsuccess = async function (event) {
      //   // Entity found in db.
      //   try {
      //     let e: T;
      //     if (invalidate) {
      //       e = await self.getEntityFromApi(url, factory);
      //       self.putEntity(e, factory); // Update in db.
      //     } else {
      //       if (req.result) {
      //         e = factory.make(req.result);
      //       } else {
      //         e = await self.getEntityFromApi(url, factory);
      //         self.putEntity(e, factory); // Update in db.
      //       }
      //     }
      //     resolve(e);
      //   } catch (x) {
      //     reject(x);
      //   }
      // };
    });
  }

  // public async getAllEntitiesFromDb<T>(factory: Factory<T>): Promise<T[]> {
  //   let self = this;

  //   const table: string = factory.getTableName();
  //   return new Promise<T[]>(function (resolve, reject) {
  //     let res: T[] = [];
  //     let os = self.db!.transaction([table], 'readonly').objectStore(table);
  //     let req = os.openCursor();
  //     req.onerror = async function (event: any) {
  //       reject(event);
  //     };
  //     req.onsuccess = function (_event) {
  //       let cursor = req.result;
  //       if (cursor) {
  //         const o = factory.make(cursor.value);
  //         res.push(o);
  //         cursor.continue();
  //       } else {
  //         resolve(res);
  //       }
  //     };
  //   });
  // }

  // public async getEntitiesFromDbByIndex<T>(range: any, index: string, factory: Factory<T>): Promise<T[]> {
  //   const self = this;
  //   const table = factory.getTableName();

  //   return new Promise<T[]>(function (resolve, reject) {
  //     const res: T[] = [];
  //     // const os = self.db!.transaction([table], 'readonly').objectStore(table);

  //     const req = (() => {
  //       const _range = IDBKeyRange.only(range);
  //       // const indx = os.index(index);
  //       // return indx.openCursor(_range);
  //     })();

  //     // req.onerror = async function (event: any) {
  //     //   reject(event);
  //     // };

  //     // req.onsuccess = function (_event) {
  //     //   const cursor = req.result;
  //     //   if (cursor) {
  //     //     const o = factory.make(cursor.value);
  //     //     res.push(o);
  //     //     cursor.continue();
  //     //   } else {
  //     //     resolve(res);
  //     //   }
  //     // };
  //   });
  // }

  async getUser(id: string): Promise<User> {
    return this.getEntityFromApi(User.getUrl(id), User.getFactory());
  }

  async getUserFromApi(id: string): Promise<User> {
    return this.getEntityFromApi(User.getUrl(id), User.getFactory());
  }

  async getTenantUnit(id: string, invalidate = false): Promise<TenantUnit> {
    return this.getEntity(id, TenantUnit.getFactory(), TenantUnit.getUrl(id), invalidate);
  }

  async getTenantUnitFromApi(id: string): Promise<TenantUnit> {
    return this.getEntityFromApi(TenantUnit.getUrl(id), TenantUnit.getFactory());
  }

  async getApartment(tenantUnitId: string, id: string): Promise<Apartment> {
    return this.getEntityFromApi(Apartment.getUrl(tenantUnitId, id), Apartment.getFactory());
  }

  async getBuilding(tenantUnitId: string, id: string): Promise<Building> {
    return this.getEntity(id, Building.getFactory(), Building.getUrl(tenantUnitId, id));
  }

  // async getEntrancesByBuildingId(buildingId: string): Promise<Entrance[]> {
  //   const entrances = await this.getEntitiesFromDbByIndex(buildingId, 'buildingId', Entrance.getFactory());
  //   return entrances.filter((e) => !e.deleted);
  // }

  // async getLevelsByBuildingId(buildingId: string): Promise<Level[]> {
  //   const levels = await this.getEntitiesFromDbByIndex(buildingId, 'buildingId', Level.getFactory());
  //   return levels.filter((l) => !l.deleted);
  // }

  // async getRoomsByApartmentId(apartmentId: string): Promise<Room[]> {
  //   const rooms = await this.getEntitiesFromDbByIndex(apartmentId, 'apartmentId', Room.getFactory());
  //   return rooms.filter((r) => !r.deleted);
  // }
  public async getPostMessagesFromDb(): Promise<PostMessage[]> {
    const self = this;
    return new Promise<PostMessage[]>(function (resolve, reject) {
      const uc = self.cache;
      if (!uc) {
        throw new Error('Cannot subscribe without being signed in.');
      }

      const messages: PostMessage[] = [];
      // const os = self.db!.transaction(['post_messages'], 'readonly').objectStore('post_messages');

      // const req: IDBRequest<IDBCursorWithValue | null> = (() => {
      //   // const index = os.index('created');
      //   // return index.openCursor(null, 'next');
      // })();

      // req.onerror = async function (event: any) {
      //   console.log('Error loading active subscription from db: ' + event);
      //   reject(event);
      // };

      // req.onsuccess = async function (_event: any) {
      //   const cursor = req.result;
      //   if (cursor) {
      //     messages.push(new PostMessage(cursor.value));
      //     cursor.continue();
      //   } else {
      //     resolve(messages);
      //   }
      // };
    });
  }

  async getPostFromApi(tenantUnitId: string, postId: string): Promise<Post> {
    return this.getEntityFromApi(Post.getUrl(tenantUnitId, postId), Post.getFactory());
  }

  async getEventFromApi(tenantUnitId: string, eventId: string): Promise<Event> {
    return this.getEntityFromApi(Event.getUrl(tenantUnitId, eventId), Event.getFactory());
  }

  async getGroupFromApi(tenantUnitId: string, groupId: string): Promise<Group> {
    return this.getEntityFromApi(Group.getUrl(tenantUnitId, groupId), Group.getFactory());
  }

  async getR20nCategory(tenantUnitId: string, id: string): Promise<R20nCategory> {
    return this.getEntity(id, R20nCategory.getFactory(), R20nCategory.getUrl(tenantUnitId, id));
  }

  async getR20nItem(tenantUnitId: string, id: string): Promise<R20nItem> {
    return this.getEntity(id, R20nItem.getFactory(), R20nItem.getUrl(tenantUnitId, id));
  }

  async getChat(tenantUnitId: string, id: string): Promise<Chat> {
    return this.getEntity(id, Chat.getFactory(), Chat.getUrl(tenantUnitId, id));
  }

  async getEvent(tenantUnitId: string, id: string): Promise<Event> {
    return this.getEntity(id, Event.getFactory(), Event.getUrl(tenantUnitId, id));
  }

  async getGroup(tenantUnitId: string, id: string): Promise<Group> {
    return this.getEntity(id, Group.getFactory(), Group.getUrl(tenantUnitId, id));
  }

  async getBookablesFromDBByType(type: BookableType): Promise<Bookable[]> {
    const self = this;
    return new Promise<Bookable[]>(function (resolve, reject) {
      if (self.db == null) {
        throw new Error('DB was not initialized.');
      }

      const factory = Bookable.getFactory();
      const table: string = factory.getTableName();
      const res: Bookable[] = [];
      // const os = self.db.transaction([table], 'readonly').objectStore(table);

      // const req: IDBRequest<IDBCursorWithValue | null> = (() => {
      //   //const range = IDBKeyRange.only([type]);
      //   const index = os.index('created');
      //   return index.openCursor(null, 'next');
      // })();

      // req.onerror = async function (event: any) {
      //   reject(event);
      // };

      // req.onsuccess = function (_event) {
      //   const cursor = req.result;
      //   if (cursor) {
      //     const o = factory.make(cursor.value);
      //     if (o.type === type) {
      //       res.push(o);
      //     }
      //     cursor.continue();
      //   } else {
      //     resolve(res);
      //   }
      // };
    });
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  async getBookable(tenantUnitId: string, id: string, invalidate: boolean = false): Promise<Bookable> {
    return this.getEntity(id, Bookable.getFactory(), Bookable.getUrl(tenantUnitId, id), invalidate);
  }

  async getGenericBookableCategory(tenantUnitId: string, id: string): Promise<GenericBookableCategory> {
    return this.getEntity(id, GenericBookableCategory.getFactory(), GenericBookableCategory.getUrl(tenantUnitId, id));
  }

  async getApartmentsByTenantUnitId(tenantUnitId: string): Promise<Apartment[]> {
    return await this.getEntitiesFromApi(Apartment.getAllUrl(tenantUnitId), null);
  }

  async getEntrance(tenantUnitId: string, id: string): Promise<Entrance> {
    return this.getEntityFromApi(Entrance.getUrl(tenantUnitId, id), Entrance.getFactory());
  }

  async getUniqueEntrancesByApartments(apartments: Apartment[], entrances: Entrance[]): Promise<Entrance[]> {
    const entrancesReturn = [];
    const addedEntranceIds: Set<string> = new Set<string>();
    for (let i = 0; i < apartments.length; i++) {
      const a: Apartment = apartments[i];
      for (let j = 0; j < a.entranceIds.length; j++) {
        const entranceId: string = a.entranceIds[j];
        if (!addedEntranceIds.has(entranceId)) {
          const e = entrances.find((ent) => ent.id === entranceId);
          if (e !== undefined && !e.deleted) {
            addedEntranceIds.add(e.id);
            entrancesReturn.push(e);
          }
        }
      }
    }
    return entrancesReturn;
  }
  // async getUniqueEntrancesByApartment(a: Apartment): Promise<Entrance[]> {
  //   const entrances = [];
  //   const addedEntranceIds: Set<string> = new Set<string>();
  //   for (let i = 0; i < a.entranceIds.length; i++) {
  //     const entranceId: string = a.entranceIds[i];
  //     if (!addedEntranceIds.has(entranceId)) {
  //       let e: Entrance;
  //       try {
  //         e = await this.getEntrance(a.tenantUnitId, entranceId);
  //       } catch (err) {
  //         console.log('Error fetching Entrance from Apartment: ', a);
  //       }
  //       if (e) {
  //         addedEntranceIds.add(e.id);
  //         entrances.push(e);
  //       }
  //     }
  //   }
  //   return entrances;
  // }

  //#endregion

  //#region Set
  //#endregion

  //#region Delete

  private async delete<T extends Identifiable>(factory: Factory<T>, o: T, url: string): Promise<T> {
    if (!o) {
      throw new Error('Object must be present.');
    }

    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot add an object without being signed in.');
    }

    const res = (await this.send('DELETE', url, undefined, cache)) as DataResponse;
    if (!res.data) {
      throw new Error('Data not found.');
    }

    o = factory.make(res.data);

    // if (observable) {
    //   await observable.next(o);
    //   await observable.next(new SubscriberSentinel()); // Send signal that object has been sent.
    // }

    return o;
  }

  public async deleteTenantUnit(t: TenantUnit): Promise<TenantUnit> {
    return this.delete(TenantUnit.getFactory(), t, TenantUnit.getUrl(t.id));
  }

  public async deleteRoom(r: Room): Promise<Room> {
    return this.delete(Room.getFactory(), r, Room.getUrl(r.tenantUnitId, r.buildingId, r.id));
  }

  public async deleteApartment(a: Apartment, rooms: Room[]): Promise<Apartment> {
    if (rooms) {
      for (let i = 0; i < rooms.length; i++) {
        await this.deleteRoom(rooms[i]);
      }
    }
    return this.delete(Apartment.getFactory(), a, Apartment.getUrl(a.tenantUnitId, a.id));
  }

  public async deletePost(p: Post): Promise<Post> {
    return this.delete(Post.getFactory(), p, Post.getUrl(p.tenantUnitId, p.id));
  }

  public async deletePostMessage(pm: PostMessage): Promise<PostMessage> {
    return this.delete(PostMessage.getFactory(), pm, PostMessage.getUrl(pm.tenantUnitId, pm.postId, pm.id));
  }

  public async deleteEvent(e: Event): Promise<Event> {
    return this.delete(Event.getFactory(), e, Event.getUrl(e.tenantUnitId, e.id));
  }

  public async deleteGroup(g: Group): Promise<Group> {
    return this.delete(Group.getFactory(), g, Group.getUrl(g.tenantUnitId, g.id));
  }

  public async deleteStory(s: Story): Promise<Story> {
    return this.delete(Story.getFactory(), s, Story.getUrl(s.tenantUnitId, s.id));
  }

  public async deleteR20nCategory(c: R20nCategory): Promise<R20nCategory> {
    return this.delete(R20nCategory.getFactory(), c, R20nCategory.getUrl(c.tenantUnitId, c.id));
  }

  public async deleteR20nItem(i: R20nItem): Promise<R20nItem> {
    return this.delete(R20nItem.getFactory(), i, R20nItem.getUrl(i.tenantUnitId, i.id));
  }

  public async deleteBookable(s: Bookable): Promise<Bookable> {
    return this.delete(Bookable.getFactory(), s, Bookable.getUrl(s.tenantUnitId, s.id));
  }

  public async deleteGenericBookableCategory(c: GenericBookableCategory): Promise<GenericBookableCategory> {
    return this.delete(GenericBookableCategory.getFactory(), c, GenericBookableCategory.getUrl(c.tenantUnitId, c.id));
  }

  public async deleteUserRole(userId: string, subject: string, scope: Scope): Promise<User> {
    if (!userId) {
      throw new Error('User ID must be present when adding role.');
    }

    if (!subject) {
      throw new Error('Subject must be present when adding role.');
    }

    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot add role without being signed in.');
    }

    const res = (await this.send(
      'DELETE',
      `/users/${userId}/roles/${subject}/${scope}`,
      undefined,
      cache
    )) as DataResponse;

    if (!res.data) {
      throw new Error('Data in response after adding user role.');
    }

    const u = User.getFactory().make(res.data);

    return u;
  }
  public async deleteBuilding(b: Building): Promise<Building> {
    return this.delete(Building.getFactory(), b, Building.getUrl(b.tenantUnitId, b.id));
  }

  public async deleteLevel(l: Level): Promise<Level> {
    return this.delete(Level.getFactory(), l, Level.getUrl(l.tenantUnitId, l.buildingId, l.id));
  }

  public async deleteEntrance(e: Entrance): Promise<Entrance> {
    return this.delete(Entrance.getFactory(), e, Entrance.getUrl(e.tenantUnitId, e.id));
  }

  public async deleteApartmentEmailInvite(inv: ApartmentEmailInvite): Promise<Invite> {
    return this.delete(
      ApartmentEmailInvite.getFactory(),
      inv,
      ApartmentEmailInvite.getUrl(inv.tenantUnitId, inv.apartmentId, inv.id)
    );
  }

  public async deleteDigitalSignage(d: DigitalSignage): Promise<DigitalSignage> {
    return this.delete(DigitalSignage.getFactory(), d, DigitalSignage.getUrl(d.tenantUnitId, d.id));
  }

  public async deleteBooking(b: Booking): Promise<Booking> {
    return this.delete(Booking.getFactory(), b, Booking.getUrl(b.tenantUnitId, b.bookableId, b.id));
  }

  // Remove all roles for given tenant unit
  public async removeUserFromTenantUnit(user: User) {
    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot remove user from tenant unit without being signed in');
    }

    const tenantUnitId = this.getActiveTenantUnitId();

    const res = (await this.send(
      'PUT',
      `/tenant_units/${tenantUnitId}/users/${user.id}/remove`,
      {},
      cache
    )) as DataResponse;

    return res.data;
  }

  public async getAllDigitalSignages(tenantUnitId: string): Promise<DigitalSignage[]> {
    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot approve connections without being signed in.');
    }

    const res = (await this.send(
      'GET',
      `/tenant_units/${tenantUnitId}/digital_signage/get_all`,
      undefined,
      cache
    )) as DataResponse;

    if (!res.data) {
      throw new Error('Data not found.');
    }

    return res.data;
  }

  public async severDigitalSignageConnection(
    tenantUnitId: string,
    digitalSignageId: string,
    connectionId: string
  ): Promise<DigitalSignageConnection> {
    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot approve connections without being signed in.');
    }

    const res = (await this.send(
      'DELETE',
      `/tenant_units/${tenantUnitId}/digital_signage/${digitalSignageId}/connections/${connectionId}/sever`,
      undefined,
      cache
    )) as DataResponse;

    if (!res.data) {
      throw new Error('Data not found.');
    }

    const f: Factory<DigitalSignageConnection> = DigitalSignageConnection.getFactory();
    const con = f.make(res.data);

    if (this.digitalSignageConnections$) {
      await this.digitalSignageConnections$.next(con);
      await this.digitalSignageConnections$.next(new SubscriberSentinel()); // Send signal that object has been sent.
    }

    return con;
  }

  //#endregion

  //#region Update

  private async edit<T extends Identifiable>(factory: Factory<T>, o: T, url: string): Promise<T> {
    if (!o) {
      throw new Error('Object must be present.');
    }

    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot edit an object without being signed in.');
    }

    const res = (await this.send('PUT', url, { data: o }, cache)) as DataResponse;
    if (!res.data) {
      throw new Error('Data not found.');
    }

    return o;
  }

  public async editTenantUnit(tu: TenantUnit): Promise<TenantUnit> {
    return this.edit(TenantUnit.getFactory(), tu, TenantUnit.getUrl(tu.id));
  }

  public async editRoom(r: Room): Promise<Room> {
    return this.edit(Room.getFactory(), r, Room.getUrl(r.tenantUnitId, r.buildingId, r.id));
  }

  public async editApartment(a: Apartment): Promise<Apartment> {
    return this.edit(Apartment.getFactory(), a, Apartment.getUrl(a.tenantUnitId, a.id));
  }

  public async editPost(p: Post): Promise<Post> {
    return this.edit(Post.getFactory(), p, Post.getUrl(p.tenantUnitId, p.id));
  }

  public async editEvent(e: Event): Promise<Event> {
    return this.edit(Event.getFactory(), e, Event.getUrl(e.tenantUnitId, e.id));
  }

  public async editGroup(g: Group): Promise<Group> {
    return this.edit(Group.getFactory(), g, Group.getUrl(g.tenantUnitId, g.id));
  }

  public async editStory(s: Story): Promise<Story> {
    return this.edit(Story.getFactory(), s, Story.getUrl(s.tenantUnitId, s.id));
  }

  public async editResidentialInformationCategory(c: R20nCategory): Promise<R20nCategory> {
    return this.edit(R20nCategory.getFactory(), c, R20nCategory.getUrl(c.tenantUnitId, c.id));
  }

  public async editResidentialInformationItem(i: R20nItem): Promise<R20nItem> {
    return this.edit(R20nItem.getFactory(), i, R20nItem.getUrl(i.tenantUnitId, i.id));
  }

  public async editBookable(b: Bookable): Promise<Bookable> {
    return this.edit(Bookable.getFactory(), b, Bookable.getUrl(b.tenantUnitId, b.id));
  }

  public async editGenericBookableCategory(b: GenericBookableCategory): Promise<GenericBookableCategory> {
    return this.edit(GenericBookableCategory.getFactory(), b, GenericBookableCategory.getUrl(b.tenantUnitId, b.id));
  }

  public async editBuilding(b: Building): Promise<Building> {
    return this.edit(Building.getFactory(), b, Building.getUrl(b.tenantUnitId, b.id));
  }

  public async editLevel(l: Level): Promise<Level> {
    return this.edit(Level.getFactory(), l, Level.getUrl(l.tenantUnitId, l.buildingId, l.id));
  }

  public async editEntrance(e: Entrance): Promise<Entrance> {
    return this.edit(Entrance.getFactory(), e, Entrance.getUrl(e.tenantUnitId, e.id));
  }

  public async sendInviteEmail(inv: ApartmentEmailInvite): Promise<ApartmentEmailInvite> {
    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot send email without being signed in.');
    }

    const res = (await this.send(
      'PUT',
      `/tenant_units/${inv.tenantUnitId}/apartments/${inv.apartmentId}/invites/${inv.id}/send`,
      {},
      cache
    )) as DataResponse;

    if (!res.data) {
      throw new Error('Data not found.');
    }

    inv = new ApartmentEmailInvite(res.data);

    return inv;
  }

  public async editUser(tu: User): Promise<User> {
    return this.edit(User.getFactory(), tu, User.getUrl(tu.id));
  }

  public async editDigitalSignage(d: DigitalSignage): Promise<DigitalSignage> {
    return this.edit(DigitalSignage.getFactory(), d, DigitalSignage.getUrl(d.tenantUnitId, d.id));
  }

  public async approveDigitalSignageConnection(
    tenantUnitId: string,
    digitalSignageId: string,
    code: string
  ): Promise<DigitalSignageConnection> {
    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot approve connections without being signed in.');
    }

    const res = (await this.send(
      'PUT',
      `/tenant_units/${tenantUnitId}/digital_signage/${digitalSignageId}/connections/approve/${code}`,
      {},
      cache
    )) as DataResponse;

    if (!res.data) {
      throw new Error('Data not found.');
    }

    const f: Factory<DigitalSignageConnection> = DigitalSignageConnection.getFactory();
    const con = f.make(res.data);

    return con;
  }

  public async reloadDigitalSignage(
    tenantUnitId: string,
    digitalSignageId: string,
    establishedConnectionId: string
  ): Promise<EstablishedConnection> {
    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot approve connections without being signed in.');
    }

    const res = (await this.send(
      'PUT',
      `/tenant_units/${tenantUnitId}/digital_signage/${digitalSignageId}/connections/${establishedConnectionId}/reload`,
      {},
      cache
    )) as DataResponse;

    if (!res.data) {
      throw new Error('Data not found.');
    }

    const f: Factory<DigitalSignageConnection> = DigitalSignageConnection.getFactory();
    const con = f.make(res.data);

    if (!(con instanceof EstablishedConnection)) {
      throw new Error('Wrong type of connection recieved after requesting DigitalSignage reload.');
    }

    return con;
  }

  //#endregion

  //#region Add

  private async add<T extends Identifiable>(
    factory: Factory<T>,
    o: T,
    url: string,
    observable?: AwaitingSubject<T>,
    extra?: any
  ): Promise<T> {
    if (!o) {
      throw new Error('Object must be present.');
    }

    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot add an object without being signed in.');
    }

    const body = extra ? { data: o, extra: extra } : { data: o };

    const res = (await this.send('POST', url, body, cache)) as DataResponse;
    if (!res.data) {
      throw new Error('Data not found.');
    }

    o = factory.make(res.data);

    return o;
  }

  private async addImage<T extends Identifiable>(
    factory: Factory<T>,
    o: T,
    f: File,
    url: string,
    observable?: AwaitingSubject<T>
  ): Promise<T> {
    if (!o) {
      throw new Error("Can't add image without an entity.");
    } else if (!f) {
      throw new Error("Can't add image without file.");
    }

    const c = this.cache;
    if (!c) {
      throw new Error("Can't add image without being signed in.");
    }

    o = await new Promise((resolve, reject) => {
      let formData: FormData = new FormData();
      let xhr: XMLHttpRequest = new XMLHttpRequest();

      formData.append('image', f);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const res = JSON.parse(xhr.response) as DataResponse;
            o = factory.make(res.data);
            resolve(o);
          } else {
            reject(xhr.response);
          }
        }
      };

      xhr.open('PUT', this.apiUrl + url + '/image', true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + c.accessToken);
      xhr.send(formData);
    });

    return o;
  }

  // Returns full URL to file.
  public async addPdf(f: File): Promise<string> {
    if (!f) {
      throw new Error("Can't upload PDF without a file.");
    }

    const c = this.cache;
    if (!c) {
      throw new Error("Can't add file without being signed in.");
    }
    return await new Promise((resolve, reject) => {
      let formData: FormData = new FormData();
      let xhr: XMLHttpRequest = new XMLHttpRequest();

      formData.append('file', f);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const res = JSON.parse(xhr.response) as DataResponse;
            resolve(res.data);
          } else {
            reject(xhr.response);
          }
        }
      };

      xhr.open('POST', this.apiUrl + '/file/upload', true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + c.accessToken);
      xhr.send(formData);
    });
  }

  // Returns file name.
  public async addGenericImage(f: File): Promise<string> {
    if (!f) {
      throw new Error("Can't upload generic image without a file.");
    }

    const c = this.cache;
    if (!c) {
      throw new Error("Can't upload generic image without being signed in.");
    }

    return await new Promise((resolve, reject) => {
      let formData: FormData = new FormData();
      let xhr: XMLHttpRequest = new XMLHttpRequest();

      formData.append('image', f);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const res = JSON.parse(xhr.response) as DataResponse;
            resolve(res.data);
          } else {
            reject(xhr.response);
          }
        }
      };

      xhr.open('POST', this.apiUrl + '/image/upload', true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + c.accessToken);
      xhr.send(formData);
    });
  }

  public async addTenantUnit(t: TenantUnit): Promise<TenantUnit> {
    return this.add(TenantUnit.getFactory(), t, TenantUnit.getUrl(), this.tenantUnits$);
  }

  public async addImageToTenantUnit(tu: TenantUnit, f: File): Promise<TenantUnit> {
    return this.addImage(TenantUnit.getFactory(), tu, f, TenantUnit.getUrl(tu.id), this.tenantUnits$);
  }

  public async addRoom(r: Room): Promise<Room> {
    return this.add(Room.getFactory(), r, Room.getUrl(r.tenantUnitId, r.buildingId), this.rooms$);
  }

  public async addApartment(a: Apartment): Promise<Apartment> {
    return this.add(Apartment.getFactory(), a, Apartment.getUrl(a.tenantUnitId), this.apartments$);
  }

  public async addPost(p: Post): Promise<Post> {
    return this.add(Post.getFactory(), p, Post.getUrl(p.tenantUnitId), this.posts$);
  }

  public async addImageToPost(p: Post, f: File): Promise<Post> {
    return this.addImage(Post.getFactory(), p, f, Post.getUrl(p.tenantUnitId, p.id), this.posts$);
  }

  public async addPostMessage(pm: PostMessage): Promise<PostMessage> {
    return this.add(PostMessage.getFactory(), pm, PostMessage.getUrl(pm.tenantUnitId, pm.postId), this.postMessages$);
  }

  public async addEvent(e: Event): Promise<Event> {
    return this.add(Event.getFactory(), e, Event.getUrl(e.tenantUnitId), this.events$);
  }

  public async addImageToEvent(e: Event, f: File): Promise<Event> {
    return this.addImage(Event.getFactory(), e, f, Event.getUrl(e.tenantUnitId, e.id), this.events$);
  }

  public async addGroup(g: Group): Promise<Group> {
    return this.add(Group.getFactory(), g, Group.getUrl(g.tenantUnitId), this.groups$);
  }

  public async addImageToGroup(g: Group, f: File): Promise<Group> {
    return this.addImage(Group.getFactory(), g, f, Group.getUrl(g.tenantUnitId, g.id), this.groups$);
  }

  public async addStory(s: Story, sendPushNotification: boolean = false): Promise<Story> {
    return this.add(Story.getFactory(), s, Story.getUrl(s.tenantUnitId), this.stories$, {
      sendPushNotification: sendPushNotification,
    });
  }

  public async addImageToStory(s: Story, f: File): Promise<Story> {
    return this.addImage(Story.getFactory(), s, f, Story.getUrl(s.tenantUnitId, s.id), this.stories$);
  }

  public async addResidentialInformationCategory(c: R20nCategory): Promise<R20nCategory> {
    return this.add(R20nCategory.getFactory(), c, R20nCategory.getUrl(c.tenantUnitId), this.r20nCategories$);
  }

  public async addResidentialInformationItem(i: R20nItem): Promise<R20nItem> {
    return this.add(R20nItem.getFactory(), i, R20nItem.getUrl(i.tenantUnitId), this.r20nItems$);
  }

  public async addImageToResidentialInformationItem(i: R20nItem, f: File): Promise<R20nItem> {
    return this.addImage(R20nItem.getFactory(), i, f, R20nItem.getUrl(i.tenantUnitId, i.id), this.r20nItems$);
  }

  public async addBookable(b: Bookable): Promise<Bookable> {
    return this.add(Bookable.getFactory(), b, Bookable.getUrl(b.tenantUnitId), this.bookables$);
  }

  public async addImageToBookable(b: Bookable, f: File): Promise<Bookable> {
    return this.addImage(Bookable.getFactory(), b, f, Bookable.getUrl(b.tenantUnitId, b.id), this.bookables$);
  }

  public async addGenericBookableCategory(c: GenericBookableCategory): Promise<GenericBookableCategory> {
    return this.add(
      GenericBookableCategory.getFactory(),
      c,
      GenericBookableCategory.getUrl(c.tenantUnitId),
      this.genericBookableCategories$
    );
  }

  public async addImageToGenericBookableCategory(
    c: GenericBookableCategory,
    f: File
  ): Promise<GenericBookableCategory> {
    return this.addImage(
      GenericBookableCategory.getFactory(),
      c,
      f,
      GenericBookableCategory.getUrl(c.tenantUnitId, c.id),
      this.genericBookableCategories$
    );
  }

  public async addUserRole(userId: string, subject: string, scope: Scope): Promise<User> {
    if (!userId) {
      throw new Error('User ID must be present when adding role.');
    }

    if (!subject) {
      throw new Error('Subject must be present when adding role.');
    }

    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot add role without being signed in.');
    }

    const res = (await this.send('PUT', `/users/${userId}/roles/${subject}/${scope}`, {}, cache)) as DataResponse;

    if (!res.data) {
      throw new Error('Data in response after adding user role.');
    }

    const u = User.getFactory().make(res.data);
    // if (this.users$) {
    //   this.users$.next(u);
    //   this.users$.next(new SubscriberSentinel());
    // }

    return u;
  }

  // Gets all Srenity `agent`s with a `type` of `access_group` that exists for this tenant unit
  public async getSrenityAccessGroups(tenantUnitId: string): Promise<SrenityAccessGroup[]> {
    if (!tenantUnitId) {
      throw new Error('Tenant Unit ID must be present when getting access groups.');
    }

    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot get access groups without being signed in.');
    }

    const res = (await this.send(
      'GET',
      `/tenant_units/${tenantUnitId}/srenity/access_group`,
      null,
      cache
    )) as DataResponse;

    if (!res.data) {
      throw new Error('Data in response after getting Srenity access groups.');
    }

    return res.data;
  }

  // Gets a Srenity `agent` with a `type` of `person`
  public async getSrenityPerson(tenantUnitId: string, userId: string): Promise<SrenityPerson | null> {
    if (!tenantUnitId) {
      throw new Error('Tenant Unit ID must be present when getting a Srenity person.');
    }

    if (!userId) {
      throw new Error('User ID must be present when getting a Srenity person.');
    }

    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot get a Srenity person without being signed in.');
    }

    const res = (await this.send(
      'GET',
      `/tenant_units/${tenantUnitId}/srenity/person/${userId}`,
      null,
      cache
    )) as DataResponse;

    if (!res.data) {
      throw new Error(
        `Data in response after getting a Srenity person for tenant_unit ${tenantUnitId} with user ${userId}.`
      );
    }

    return res.data;
  }

  // Creates a Srenity `agent` with a `type` of `person`
  public async postSrenityPerson(
    tenantUnitId: string,
    userId: string,
    accessGroups: SrenityAccessGroup[]
  ): Promise<SrenityPerson> {
    if (!tenantUnitId) {
      throw new Error('Tenant Unit ID must be present when creating a Srenity person.');
    }

    if (!userId) {
      throw new Error('User ID must be present when creating a Srenity person.');
    }

    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot create a Srenity person without being signed in.');
    }

    const res = (await this.send(
      'POST',
      `/tenant_units/${tenantUnitId}/srenity/person/${userId}`,
      {
        data: accessGroups,
      },
      cache
    )) as DataResponse;

    if (!res.data) {
      throw new Error(
        `Data in response after creating a Srenity person for tenant_unit ${tenantUnitId} with user ${userId}.`
      );
    }

    return res.data;
  }

  // Replaces a Srenity `agent` with a `type` of `person` with supplied data
  public async putSrenityPerson(
    tenantUnitId: string,
    userId: string,
    srenityPerson: SrenityPerson
  ): Promise<SrenityPerson> {
    if (!tenantUnitId) {
      throw new Error('Tenant Unit ID must be present when updating a Srenity person.');
    }

    if (!userId) {
      throw new Error('User ID must be present when updating a Srenity person.');
    }

    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot update a Srenity person without being signed in.');
    }

    const res = (await this.send(
      'PUT',
      `/tenant_units/${tenantUnitId}/srenity/person/${userId}`,
      {
        data: srenityPerson,
      },
      cache
    )) as DataResponse;

    // if (!res.data) {
    //   throw new Error(
    //     `Data in response after updating a Srenity person for tenant_unit ${tenantUnitId} with user ${userId}.`
    //   );
    // }

    return res.data;
  }

  public async addBuilding(b: Building): Promise<Building> {
    return this.add(Building.getFactory(), b, Building.getUrl(b.tenantUnitId), this.buildings$);
  }

  public async addLevel(l: Level): Promise<Level> {
    return this.add(Level.getFactory(), l, Level.getUrl(l.tenantUnitId, l.buildingId), this.levels$);
  }

  public async addEntrance(e: Entrance): Promise<Entrance> {
    return this.add(Entrance.getFactory(), e, Entrance.getUrl(e.tenantUnitId), this.entrances$);
  }

  public async addApartmentEmailInvite(i: ApartmentEmailInvite): Promise<Invite> {
    return this.add(
      ApartmentEmailInvite.getFactory(),
      i,
      ApartmentEmailInvite.getUrl(i.tenantUnitId, i.apartmentId),
      this.invites$
    );
  }

  public async addDigitalSignage(d: DigitalSignage): Promise<DigitalSignage> {
    return this.add(DigitalSignage.getFactory(), d, DigitalSignage.getUrl(d.tenantUnitId), this.digitalSignages$);
  }

  //#endregion

  //#region Utils

  public async isReady(): Promise<void> {
    const self = this;
    return new Promise<void>(function (resolve, reject) {
      const check = function () {
        if (self.db) {
          resolve();
          return;
        } else {
          setTimeout(check, 0); // Next slot in event loop.
        }
      };
      check();
    });
  }

  async sleep(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  // Two decimal places.
  secondsToMinutes(seconds: number): number {
    if (seconds < 0 || !isFinite(seconds)) {
      throw new Error('Input must be a non-negative finite number.');
    }
    const hours = seconds / 60;
    return parseFloat(hours.toFixed(2));
  }

  // Two decimal places.
  secondsToHours(seconds: number): number {
    if (seconds < 0 || !isFinite(seconds)) {
      throw new Error('Input must be a non-negative finite number.');
    }
    const hours = seconds / 3600;
    return parseFloat(hours.toFixed(2));
  }

  //#endregion
}

//#region Helper classes

export class UserCache {
  accessToken: string;
  refreshToken: string;
  tenantUnitCache?: TenantUnitCache;
  userId: string;
  user: User;
  polled: boolean;
  private finalInitialPollPackage?: number;
  private receivedInitialPollPackages: boolean[];

  constructor(accessToken: string, refreshToken: string, userId: string) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.userId = userId;
    this.polled = false;
    this.receivedInitialPollPackages = [];
  }

  public setUser(u: User) {
    this.user = u;
  }

  public setFinalInitialPollPackage(finalInitialPollPackage: number) {
    if (!this.polled) {
      this.finalInitialPollPackage = finalInitialPollPackage;
    }
  }

  public setReceivedInitialPollPackage(receivedInitialPollPackage: number) {
    if (!this.polled) {
      this.receivedInitialPollPackages[receivedInitialPollPackage - 1] = true;

      if (this.finalInitialPollPackage) {
        // Check if done.
        let ok = true;
        for (let i = 0; i < this.finalInitialPollPackage; i++) {
          if (!this.receivedInitialPollPackages[i]) {
            ok = false;
            break;
          }
        }
        if (ok) {
          this.polled = true;
          this.receivedInitialPollPackages = [];
          this.finalInitialPollPackage = undefined;
        }
      }
    }
  }

  public async hasPolled(): Promise<void> {
    let self = this;
    return new Promise<void>(function (resolve, reject) {
      let check = function () {
        if (self.polled) {
          resolve();
          return;
        } else {
          setTimeout(check, 0); // Next slot in event loop.
        }
      };
      check();
    });
  }
}

class TenantUnitCache {
  userCache: UserCache;
  tenantUnitId: string;
  tenantUnit: TenantUnit;
  polled: boolean;
  private finalInitialPollPackage?: number;
  private receivedInitialPollPackages: boolean[];

  constructor(userCache: UserCache, tenantUnitId: string) {
    this.userCache = userCache;
    this.tenantUnitId = tenantUnitId;
    this.polled = false;
    this.finalInitialPollPackage = undefined;
    this.receivedInitialPollPackages = [];
  }

  setTenantUnit(t: TenantUnit) {
    this.tenantUnit = t;
  }

  setFinalInitialPollPackage(finalInitialPollPackage: number) {
    if (!this.polled) {
      this.finalInitialPollPackage = finalInitialPollPackage;
    }
  }

  setReceivedInitialPollPackage(receivedInitialPollPackage: number) {
    if (!this.polled) {
      this.receivedInitialPollPackages[receivedInitialPollPackage - 1] = true;

      if (this.finalInitialPollPackage) {
        // Check if done.
        let ok = true;
        for (let i = 0; i < this.finalInitialPollPackage; i++) {
          if (!this.receivedInitialPollPackages[i]) {
            ok = false;
            break;
          }
        }
        if (ok) {
          this.polled = true;
          this.receivedInitialPollPackages = [];
          this.finalInitialPollPackage = null;
        }
      }
    }
  }

  async hasPolled(): Promise<void> {
    const self = this;
    return new Promise<void>(function (resolve, reject) {
      const check = function () {
        if (self.polled) {
          resolve();
          return;
        } else {
          setTimeout(check, 0); // Next slot in event loop.
        }
      };
      check();
    });
  }
}

interface SigninResponse {
  data: {
    accessToken: string;
    refreshToken: string;
    userId: string;
  };
}

interface DataResponse {
  data: any;
  extra: any;
}

interface UserPollResponse {
  data: {
    user: any;
    notifications: any;
    invites: any;
    etag: string;
  };
  id: number;
  done: boolean;
}

interface UserPollErrorResponse {
  text: string;
}

enum UserPollRequestType {
  Signin = 1,
  TokenRefresh = 2,
  ForcePoll = 3,
  Signout = 4,
}

enum UserPollResponseType {
  Poll = 1,
  TokenRefresh = 2,
  Error = 3,
  ConnectionOk = 4,
  ConnectionError = 5,
}

interface TenantUnitPollResponse {
  data: {
    tenantUnit: any;
    buildings: any;
    entrances: any;
    levels: any;
    rooms: any;
    apartments: any;
    stories: any;
    events: any;
    posts: any;
    postMessages: any;
    bookables: any;
    genericBookableCategories: any;
    bookings: any;
    users: any;
    groups: any;
    residentialInformationCategories: any;
    residentialInformationItems: any;
    digitalSignage: any;
    digitalSignageConnections: any;
    invites: any;
    chats: any;
    etag: string;
  };
  id: number;
  done: boolean;
}

interface TenantUnitPollErrorResponse {
  text: string;
}

enum TenantUnitPollRequestType {
  Signin = 1,
  TokenRefresh = 2,
  ForcePoll = 3,
  Signout = 4,
}

enum TenantUnitPollResponseType {
  Poll = 1,
  TokenRefresh = 2,
  Error = 3,
  ConnectionOk = 4,
  ConnectionError = 5,
}

//#endregion
