import { environment } from '../../../environments/environment';
import { PublishStatus } from '../enum/publish-status';
import { Deletable } from '../interface/deletable';
import { Factory } from '../interface/factory';
import { HasTenantUnitId } from '../interface/has-tenant-unit-id';
import { Identifiable } from '../interface/identifiable';
import { Attachment, AttachmentType, NoneAttachment } from './attachment';
import { Flags } from './flags';
import { I18nString } from './i18nstring';

export class Post implements Deletable, Identifiable, HasTenantUnitId {
  id: string;
  deleted: boolean;

  tenantUnitId: string;
  userId: string;

  attachment: Attachment;
  attachmentType: AttachmentType; // NOTE: Only used for indexing in browser DB.

  status: PublishStatus;
  titles: I18nString[];
  texts: I18nString[];
  images?: string[];
  flags?: Flags;

  showAsSystemUser: boolean;

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;

    this.tenantUnitId = json.tenantUnitId;
    this.userId = json.userId;

    this.attachment = json.attachment ? Attachment.getFactory().make(json.attachment) : new NoneAttachment({});
    this.attachmentType = this.attachment.type;

    this.status = json.status ? (json.status as PublishStatus) : PublishStatus.Published;
    this.titles = json.titles ? json.titles.map((json: any) => new I18nString(json)) : [];
    this.texts = json.texts ? json.texts.map((json: any) => new I18nString(json)) : [];
    this.images = json.images ? json.images : [];

    this.showAsSystemUser = json.showAsSystemUser ? Boolean(json.showAsSystemUser) : false;
    this.flags = json.flags ? json.flags : null;

    this.created = json.created;
    this.modified = json.modified;
  }

  public static getFactory(): Factory<Post> {
    return new (class implements Factory<Post> {
      make(json: any): Post {
        return new Post(json);
      }
      getTableName(): string {
        return 'posts';
      }
    })();
  }

  static getAllUrl(tenantUnitId: string): string;
  static getAllUrl(tenantUnitId: string): string {
    return '/tenant_units/' + tenantUnitId + '/posts/all';
  }

  static getUrl(tenantUnitId: string, postId?: string): string;
  static getUrl(tenantUnitId: string, postId: string): string {
    return '/tenant_units/' + tenantUnitId + '/posts' + (postId ? '/' + postId : '');
  }

  getImageUrl(index: number = 0): string | undefined {
    return this.images[index] ? environment.blobUrl + '/images/' + this.images[index] : undefined;
  }
}
